import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { loadConfig } from "@retail/config";
import * as retailDotenv from "@retail/dotenv";
import retailGlobal from "@retail/global";

if (__IS_STANDALONE__) {
  loadDevMessages();
  loadErrorMessages();
  require("antd/dist/antd.less");
  /**
   * TODO: antd/Datepicker doesn't add prefix for animation classes.
   * Importint pure css fixes it. The issue reproduces only in standalone mode.
   */
  require("antd/lib/date-picker/style/css");
}

const ENV = __IS_STANDALONE__
  ? process.env.ENV
  : retailGlobal.get<{ ENV?: string }>("__config")?.ENV;

loadConfig(retailDotenv.load(ENV));
