export const TRANSLATION_KEYS_MAP: Record<string, string> = {
  customer_lead_is_archived: "errors.customer_lead_is_archived",
  upstream_validation_failed: "opportunity_car_stock_number_not_available",
  "fieldOptions.oppReopenReason.SUPERVISOR_STATUS_OVERWRITE":
    "fieldOptions.oppReopenReason.supervisorStatusOverwrite",
  "fieldOptions.oppReopenReason.AUTOMATIC_CAR_DOWNPRICING":
    "fieldOptions.oppReopenReason.automaticCarDownpricing",
  "customerLead.archivingReason.TEST_DATA":
    "customerLead.archivingReason.testData",
  "customerLead.archivingReason.NOT_REACHABLE":
    "customerLead.archivingReason.notReachable",
  "customerLead.archivingReason.DUPLICATE":
    "customerLead.archivingReason.duplicate",
  "customerLead.archivingReason.MERGED_DUPLICATES":
    "customerLead.archivingReason.mergedDuplicates",
  "customerLead.archivingReason.OTHER": "customerLead.archivingReason.other",
  "customerLead.archivingReason.Other": "customerLead.archivingReason.other",
  "clickToCall.initialize_call_customer_lead_archived":
    "initialize_call_customer_lead_archived",
  "clickToCall.initialize_call_contact_phone_not_found":
    "initialize_call_contact_phone_not_found",
  "customerLead.archivingReason.SPAM": `customerLead.archivingReason.spam`,
  lead_to_merge_not_found: "customerRoute.mergingForm.error.leadNotFound",
  customer_lead_order_same_car: "customerRoute.mergingForm.error.sameCarOrder",
  different_agents: "customerRoute.mergingForm.error.differentAgents",
  mergeable_customer_lead_is_archived:
    "customerRoute.mergingForm.error.archivedLead",
  order_status_invalid: "errors.order_status_invalid",
  TRADEIN_IN_PROGRESS: "fieldOptions.tradeInStatus.inProgress",
  APPRAISAL_READY: "fieldOptions.tradeInStatus.ready",
  EVALUATION_IN_PROGRESS: "fieldOptions.tradeInStatus.incomplete",
  PENDING_APPRAISAL: "fieldOptions.tradeInStatus.pendingAppraisal",
  SOLD_AND_HANDED_OVER: "fieldOptions.tradeInStatus.soldAndHandedOver",
  TRADEIN_COMPLETED: "fieldOptions.tradeInStatus.complete",
  APPRAISAL_EXPIRED: "fieldOptions.tradeInStatus.appraisalExpired",
  TRADEIN_NOT_POSSIBLE: "fieldOptions.tradeInStatus.notPossible",
  fr_BE: "customerRoute.customer.frBE",
  nl_BE: "customerRoute.customer.nlBE",
  "customerRoute.oppItem.publishingBlockerReason.eQA":
    "customerRoute.oppItem.publishingBlockerReason.EQA",
  "customerRoute.oppItem.publishingBlockerSubreason.retakeBeautyShotsDueToLowQuality":
    "customerRoute.oppItem.publishingBlockerSubreason.retakeShots",
  "customerRoute.oppItem.publishingBlockerSubreason.retakeImperfectionImagesDueToLowQuality":
    "customerRoute.oppItem.publishingBlockerSubreason.retakeImages",
  "customerRoute.oppItem.publishingBlockerSubreason.reentryCheck":
    "customerRoute.oppItem.publishingBlockerSubreason.reEntryCheck",
  "customerRoute.oppItem.publishingBlockerSubreason.returnToA1ByClaim":
    "customerRoute.oppItem.publishingBlockerSubreason.returnByClaim",
  "customerRoute.oppItem.publishingBlockerSubreason.clarifyDocumentLocationShippingStatus":
    "customerRoute.oppItem.publishingBlockerSubreason.clarifyDocLocationStatus",
  "customerRoute.oppItem.publishingBlockerSubreason.documentDuplication":
    "customerRoute.oppItem.publishingBlockerSubreason.docDuplication",
  "customerRoute.oppItem.publishingBlockerSubreason.updateCarDescription":
    "customerRoute.oppItem.publishingBlockerSubreason.updateDescription",
  "customerRoute.oppItem.publishingBlockerSubreason.clarifyCarLocationTransportStatus":
    "customerRoute.oppItem.publishingBlockerSubreason.clarifyLocation",
  "customerRoute.oppItem.publishingBlockerSubreason.solveOngoingLegalCase":
    "customerRoute.oppItem.publishingBlockerSubreason.legalCase",
};
